<template>
  <ion-page>
    <ion-content class="relative hide-overflow" :scroll-y="false"
      :fullscreen="true">
      <div :class="currentRoute === 'proPlan' ? 'h-32': 'h-48 hlg:h-56'"
           class="bg flex items-center justify-center relative">
        <button @click="back" class="absolute left-0 top-0 mt-5 ml-5">
          <ArrowLeftIcon/>
        </button>
        <img v-if="currentRoute !== 'proPlan'" src="@/assets/images/logo.svg" alt="">
      </div>
      <RoundedBigCard class="-mt-8 relative z-10 card-height" :is-home="true">
        <template slot="content">
          <router-view></router-view>

        </template>
      </RoundedBigCard>
    </ion-content>
  </ion-page>
</template>

<script>
import RoundedBigCard from "@/components/shared/RoundedBigCard";
import ArrowLeftIcon from "@/components/svg/ArrowLeftIcon";

export default {
  name: "index",
  components: {ArrowLeftIcon, RoundedBigCard},
  methods: {
    back() {
      this.$router.back();
    }
  },
  computed: {
    currentRoute() {
      return this.$route.name
    }
  }
}
</script>

<style scoped>
.card-height {
  height: 480px;
}

@media (min-height: 812px) {
  .card-height {
    height: 590px;
  }
}

.hide-overflow {
  --overflow: hidden !important;
}
</style>
